import { createTheme } from '@mui/material/styles';

import { blue, blue_back, c_error_s, c_primary, c_secondary, orange, orange_back } from "./utils/constants/ColorConst";

export const theme = createTheme({
    palette: {
      primary: {
        main: `${c_primary}`,
        light: '#F5F2FD',
      },
      secondary: {
        main: `${c_secondary}`
      },
      success: {
        main: `#096D2A`,
        light: `#EDFEF2`
      },
      error: {
        main: `${c_error_s}`
      },
      warning: {
        main: `${orange}`,
        light: `${orange_back}`
      },
      info:{
        main:`${blue_back}`,
        light:`${blue}`
      }
    }
  })