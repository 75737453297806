function convertToCSV({ data, setExport }) {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));
    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
    setExport(false);
    return csvRows.join("\n");
  }
  
  export function downloadCSV(data, filename, setExport) {
    const csvData = convertToCSV({ data: data, setExport: setExport });
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);  // Añadir el elemento al DOM
    a.click();
    document.body.removeChild(a);  // Eliminar el elemento del DOM
  }
  