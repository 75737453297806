import { Grid } from "@mui/material";
import "../../assets/styles/views/users.css";
import ColorCard from "../../components/general/Cards/ColorCard";
import { useTranslation } from "react-i18next";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AddCard, AddMembership } from "../../utils/constants/IconsConsts";
import { useNavigate } from "react-router-dom";
import AlertGeneral from "../../components/general/AlertGeneral";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_MEMBERSHIPS_FOR_TABLE } from "../../graphql/queries/membershipQueries";
import { downloadCSV } from "../../utils/constants/DownloadCSVConst";
import { format } from "date-fns";

function MembershipManagment() {
    const { t } = useTranslation("global");
    const navigate = useNavigate();
    const today = format(new Date(), 'dd-MM-yyyy');
    const [exporting, setExport] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [alert, setAlert] = useState({
        severity: "",
        message: "",
        progress: false
    });

    //lazyQuery
    const [getDataExport, { data }] = useLazyQuery(GET_MEMBERSHIPS_FOR_TABLE, {
        onCompleted: () => {
            const DataForTable = data.memberships ? data.memberships : null;
            if (DataForTable !== null) {
                const forTable = DataForTable.nodes.map((item) => {
                    return {
                        name: item.name,
                        cost: item.cost,
                        durationType: item.durationType,
                        status: item.status,
                        createdAt: item.createdAt,
                    }
                });
                downloadCSV(forTable, `${[t("Memberships.download")] + today}`, setExport);
            }
        },
        onError: () => {
            setAlert({
                ...alert,
                message: "errorr downloading",
                severity: 'error',
            });
            setOpenAlert(true);
        }
    });

    //ExportData
    useEffect(() => {
        if (exporting === true) {
            getDataExport({ variables: { paging: { limit: 1000 } } });
            setAlert({
                ...alert,
                message: [`${t("Download.message_wait")}`],
                severity: 'info',
                progress: 'true',
            });
            setOpenAlert(true);
        };
        if (exporting === false) {
            setOpenAlert(false)
        }
    }, [exporting, setExport, getDataExport,t,alert]);

    const handleClickExport = () => {
        setExport(true)
    }

    return (
        <Grid container direction="row">
            <Grid item lg={12} xs={12} className="titleHeader-users" sx={{ mb: 2 }}>
                <h1 className="h1-users">{t("Memberships.admin")}</h1>
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Membership.membership")}
                    description={t("MembershipManagment.title")}
                    list={[
                        `${t("MembershipManagment.task.list")}`,
                        `${t("MembershipManagment.task.search")}`,
                        `${t("MembershipManagment.task.edit")}`,
                        `${t("MembershipManagment.task.export")}`,
                    ]}
                    onclickCard={() => navigate("/dashboard/connect/membresias")}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Memberships.new_membership")}
                    iconCard={AddCard}
                    description={t("MembershipManagment.description.create")}
                    list={["B2C", "Talleres"]}
                    blueCard={true}
                    onclickCard={() => navigate("/dashboard/crear-membresia")}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("Memberships.export")}
                    iconCard={<FileDownloadOutlinedIcon />}
                    bloqued={exporting}
                    description={t("MembershipManagment.description.export")}
                    onclickCard={handleClickExport}
                />
            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    title={t("User.assign_membership")}
                    iconCard={AddMembership}
                    description={t("MembershipManagment.description.assign")}
                    list={[
                        `${t("MembershipManagment.task.search")}`,
                        `${t("MembershipManagment.task.select")}`,
                    ]}
                    onclickCard={() => navigate("/dashboard/asignar-membresia")}
                />

            </Grid>
            <Grid item lg={3.5} md={6} xs={12}>
                <ColorCard
                    yellowCard={true}
                    title={t("MultipleSubscription.multipleSubscription")}
                    iconCard={AddMembership}
                    description={t("MembershipManagment.description.multi")}
                    list={[
                        `${t("MembershipManagment.task.download_template")}`,
                        `${t("MembershipManagment.task.fill")}`,
                        `${t("MembershipManagment.task.attach")}`
                    ]}
                    onclickCard={() => navigate("/dashboard/multiples-subscripciones")}
                />
            </Grid>
            {openAlert ?
                <AlertGeneral
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={alert.message}
                    severity={alert.severity}
                    progress={alert.progress}
                /> : null}
        </Grid>
    )
}
export default MembershipManagment;

