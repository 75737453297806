import { styled } from "@mui/material";
import { Drawer as MuiDrawer } from '@mui/material';

const drawerWidth = 255;
export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      border: "none",
      backgroundColor: `#FAFAFB`,
      index:1,
      zIndex:2,
      margin: "none",
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      ...(!open && {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('xs')]: {
          width: theme.spacing(9)
        },
      }),
    },
  }),
);