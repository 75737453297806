import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloProvider } from "@apollo/client";
import i18next from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { client } from "./graphql/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./index.css";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
import { AuthProvider } from "./contexts/authContext";
const storedFlag = localStorage.getItem("language");

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: storedFlag ? storedFlag : "es",
  resources: {
    en: {
      global: global_en,
    },
    es: {
      global: global_es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <I18nextProvider i18n={i18next}>
          <ApolloProvider client={client}>
            <App />
          </ApolloProvider>
        </I18nextProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
