import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CardMembershipOutlinedIcon from "@mui/icons-material/CardMembershipOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import RouteIcon from "@mui/icons-material/Route";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BlockIcon from "@mui/icons-material/Block";
import AbcIcon from '@mui/icons-material/Abc';

export const DashboardIcon = <MonitorHeartOutlinedIcon />;
export const TransationsIcon = <ReceiptLongIcon />;
export const UsersIcon = <GroupOutlinedIcon />;
export const MembershipsIcon = <CardMembershipOutlinedIcon />;
export const ReportsIcons = <AnalyticsOutlinedIcon />;
export const SettingsIcon = <SettingsOutlinedIcon />;
export const PasswordOffIcon = <VisibilityOffOutlinedIcon />;
export const EyeIcon = <VisibilityIcon />;
export const CoursesIcon = <PlaylistAddIcon />;
export const PathIcon = <RouteIcon />;
export const QuestionIcon = <HelpCenterIcon />;
export const AddMembership = <BookmarkAddIcon />;
export const EditIcon = <EditOutlinedIcon />;
export const DownloadIcon = <FileDownloadOutlinedIcon />;
export const AddCard = <AddCardOutlinedIcon />;
export const AdminIcon = <EngineeringIcon />;
export const BlockedIcon = <BlockIcon />;
export const PromoIcon= <AbcIcon/>;

