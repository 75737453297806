import { gql } from "@apollo/client";

export const GET_MEMBERSHIPS = gql`
  query getMemberships($paging: OffsetPaging, $sorting: [MembershipSort!]) {
    memberships(paging: $paging,sorting:$sorting) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`;

export const GET_MEMBERSHIPS_FOR_TABLE = gql`
  query getMemberships($paging: OffsetPaging, $sorting: [MembershipSort!]) {
    memberships(paging: $paging,sorting:$sorting) {
      nodes {
        _id
        name
        cost
        durationType
        status
        createdAt
      }
      totalCount
    }
  }
`;

