
import Dashboard from './pages/dashboard';
import { BrowserRouter, Route, Routes, } from 'react-router-dom';
import Login from './pages/login';
import { useContext,} from 'react';
import { AuthContext } from './contexts/authContext';

function App() {
 const {user}=useContext(AuthContext);

  return (
        <BrowserRouter>
          <Routes>
            {user ?
              <>
                <Route path='/*' element={<Dashboard />} />
              </>
              :
              <>
                <Route path='/login' element={<Login />} />
                <Route path='/*' element={<Login />} />
              </>
            }
          </Routes>
        </BrowserRouter>
  );
}

export default App;

